import React from "react"
const H1 = ({ children, display, className }) => {
  return (
    <h1
      className={`text-2xl font-extrabold leading-relaxed ${
        display
          ? "text-4xl sm:text-5xl md:text-6xl"
          : "text-2xl sm:text-3xl md:text-4xl"
      } ${className}`}
    >
      {children}
    </h1>
  )
}

export default H1
